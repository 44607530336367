import GoogleAdsIntegrationIcon from '@/components/icons/integrations/GoogleAdsIntegrationIcon.vue'
import GoogleAdsIntegrationBigIcon from '@/components/icons/integrations/GoogleAdsIntegrationBigIcon.vue'
import FacebookAdsIntegrationIcon from '@/components/icons/integrations/FacebookAdsIntegrationIcon.vue'
import FacebookAdsIntegrationBigIcon from '@/components/icons/integrations/FacebookAdsIntegrationBigIcon.vue'
import IncomingWebhooksIntegrationIcon from '@/components/icons/integrations/IncomingWebhooksIntegrationIcon.vue'
import OutgoingWebhooksIntegrationIcon from '@/components/icons/integrations/OutgoingWebhooksIntegrationIcon.vue'

import { ROUTES } from '@/constants/routes'

export const INTEGRATIONS = {
    google: {
        id: 1,
        routeName: ROUTES.googleAdsIntegration,
        name: 'Google Ads',
        description: 'Send call conversion data directly to Google Ads to effectively optimize your marketing campaigns',
        beta: true,
        icon: () => GoogleAdsIntegrationIcon,
        iconBig: () => GoogleAdsIntegrationBigIcon,
    },
    incomingWebhooks: {
        id: 2,
        routeName: ROUTES.incomingWebhooksIntegration,
        name: 'Incoming Webhooks',
        description: 'Customize notifications to see in other platforms',
        beta: true,
        icon: () => IncomingWebhooksIntegrationIcon,
    },
    facebook: {
        id: 3,
        routeName: ROUTES.facebookAdsIntegration,
        name: 'Facebook Ads',
        description: 'Send call conversion data directly to Facebook Ads to effectively optimize your marketing campaigns',
        beta: true,
        icon: () => FacebookAdsIntegrationIcon,
        iconBig: () => FacebookAdsIntegrationBigIcon,
    },
    outgoingWebhooks: {
        id: 4,
        routeName: ROUTES.outgoingWebhooksIntegration,
        name: 'Outgoing Webhooks',
        description: 'Customize notifications to see in other platforms',
        beta: true,
        icon: () => OutgoingWebhooksIntegrationIcon,
    },
}
