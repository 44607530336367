import { defineStore } from 'pinia'

import {
    getLeads, getLeadsFiltersAndSorting, deleteLeads, exportLeads,
} from '@/api/leads-routes'

import { getActiveColumnsHeader, getActiveHeadersTable } from '@/helpers/settings-header-table-helper'
import {
    addFilterInColumn,
    checkEqualsFilter,
    createFiltersForSend,
    createSortForSend,
    getQuery,
    setFilter,
    setQuery,
    setSorting,
    updateHeaderAfterSort,
    updateSortHeaderColumn,
    updateSortingAfterReset,
    updateSortingControl,
    createRangeForSend,
} from '@/helpers/filters-sorting-header-table-helper'
import { getClearQueryLocalStorage } from '@/helpers/remove-query-local-storage-helper'
import { getFilterLocalStorageByWorkspaceId, setFilterInLocalStorageByWorkspaceId } from '@/helpers/save-filter-local-storage-helper'
import {
    getFilterOnPageInLocalStorageByWorkspaceId,
    getSortingInLocalStorageByWorkspaceId,
    setFilterOnPageInLocalStorageByWorkspaceId,
    setSortingInLocalStorageByWorkspaceId,
} from '@/helpers/save-sorting-local-storage-helper'
import { downloadFile, removeFirstPlusForSearch } from '@/helpers/app-helper'
import { getCurrentTimezone, setTimezoneInLocalStorage } from '@/helpers/workspace-timezone-helper'
import { formatDateByTzOffset, getPresets } from '@/helpers/date-helper'
import { setRangeInLocalStorageByWorkspaceId, getRangeLocalStorageByWorkspaceId } from '@/helpers/save-range-local-storage-helper'

import { useDefaultStore } from '@/store/defaultStore'
import { useAuthorizationStore } from '@/store/authorizationStore'
import { useSortFilterTableStore } from '@/store/sortFilterTableStore'
import { useAppStore } from '@/store/appStore'

import { viewPorts } from '@/constants/viewPorts'
import { LEADS_HEADERS } from '@/constants/headersTable/leadsHeadersTable'
import { SETTINGS_HEADER_TABLE } from '@/constants/headersTable/settingsHeaderTable'
import { FILTER_RULES } from '@/constants/filterRules'
import { SORT_RULES } from '@/constants/sortRules'

export const useLeadsStore = defineStore('leadsStore', {
    state: () => ({
        loading: false,
        firstLoading: true,
        actionLoading: false,
        loadingCSV: false,

        items: [],
        selected: [],

        page: 1,
        total: 1,
        onPage: 25,

        search: null,

        currentTimezone: getCurrentTimezone(SETTINGS_HEADER_TABLE.leads) || useAuthorizationStore().getWorkspaceTimezoneId,
        range: [],
        rangeForSend: {},
        currentPreset: {},

        filters: [],
        sorting: [],
        selectedFilters: [],
        selectedSorting: [],
        filtersForSend: {},
        sortingForSend: {},

        activeColumnsTable: [],
        visibleHeadersTable: [],
    }),

    getters: {
        controlParams(state) {
            return {
                filters: state.filters.map((filter) => ({
                    ...filter,
                    rule: null,
                    value: null,
                })),
                sorting: state.sorting,
            }
        },

        timezoneOffset({ currentTimezone }) {
            const appStore = useAppStore()
            return appStore.getTimezoneOffsetById(currentTimezone)
        },

        presets() {
            return getPresets(this.timezoneOffset)
        },

        bodyDateForSend({ currentTimezone, range = [] }) {
            return {
                date: range[0],
                dateTo: range[1],
                timezone: currentTimezone,
            }
        },
    },

    actions: {
        setCurrentTimezone(id) {
            this.currentTimezone = id
            setTimezoneInLocalStorage(id, SETTINGS_HEADER_TABLE.leads)
        },

        setRange(newRange) {
            this.range = newRange

            this.checkCurrentPreset(newRange)
            this.sendFiltersSorting()
        },

        setDefaultRange() {
            this.range = [formatDateByTzOffset(this.timezoneOffset), formatDateByTzOffset(this.timezoneOffset)]
        },

        checkCurrentPreset(range) {
            this.currentPreset = this.presets.find((preset) => {
                if (!preset.hasOwnProperty('range')) {
                    return true
                }

                return preset.range[0] === range[0] && preset.range[1] === range[1]
            })
        },

        changeSelectedItems(newSelectedItems) {
            this.selected = newSelectedItems
        },

        clearSelected() {
            this.selected = []
        },

        removeAllSorting() {
            this.selectedSorting = []
            this.sorting = updateSortingAfterReset(this.sorting)
        },

        resetSortFilter() {
            this.selectedFilters = []
            this.removeAllSorting()
            this.filtersForSend = null
            this.sortingForSend = {}
            this.visibleHeadersTable = updateHeaderAfterSort(this.visibleHeadersTable)
            this.saveFiltersSortingInLocalStorage()
            this.saveQuery()
            this.changePage(1)
        },

        sendFiltersSorting() {
            this.preparationFiltersForSend()
            this.preparationSortingForSend()
            this.preparationRangeForSend()
            this.saveFiltersSortingInLocalStorage()
            this.saveQuery()
            this.changePage(1)
        },

        preparationRangeForSend() {
            this.rangeForSend = createRangeForSend(this.range, this.currentPreset)
        },

        preparationFiltersForSend() {
            const newFilter = createFiltersForSend(this.selectedFilters)

            if (!checkEqualsFilter(newFilter, this.filtersForSend)) {
                this.filtersForSend = newFilter
            }
        },

        preparationSortingForSend() {
            this.sortingForSend = createSortForSend(this.selectedSorting, this.visibleHeadersTable)
            this.visibleHeadersTable = updateHeaderAfterSort(this.visibleHeadersTable, this.sortingForSend)
        },

        updateSelectedFilters(payload) {
            this.selectedFilters = payload
            this.sendFiltersSorting()
        },

        changeSelectedSortingAfterDelete(item) {
            this.selectedSorting = this.selectedSorting.filter((el) => el.key !== item.key)

            this.updateSortingForSend(item, false)
            this.sendFiltersSorting()
        },

        changeSelectedSortingAfterAdd(item) {
            this.createSelectedSorting(item)
            this.updateSortingForSend(item, true)
            this.sendFiltersSorting()
        },

        addSortingFromTable(sort) {
            const sortFilterTableStore = useSortFilterTableStore()

            this.selectedSorting = setSorting(sort, [...this.selectedSorting])
            this.visibleHeadersTable = updateSortHeaderColumn(sort, this.visibleHeadersTable)
            this.sorting = updateSortingControl(sort, this.sorting, true)
            this.sendFiltersSorting()
            sortFilterTableStore.openCloseTableSortMenu(true)
            sortFilterTableStore.openCloseSorting(true)
        },

        addFilterFromTable(filter) {
            const index = this.selectedFilters.findIndex((item) => item.key === filter.filterSettings.key)

            const sortFilterTableStore = useSortFilterTableStore()

            const { items, filterOpen } = setFilter(filter, [...this.selectedFilters])

            if (index === -1) {
                this.updateSelectedFilters(items)
            }

            sortFilterTableStore.changeIndexOpen(SETTINGS_HEADER_TABLE.leads, filterOpen)
            sortFilterTableStore.openCloseFilter(SETTINGS_HEADER_TABLE.leads)
        },

        createSelectedSorting(item, key) {
            const role = key ? SORT_RULES.find((r) => r.key === key) : SORT_RULES[0]
            this.selectedSorting = [...this.selectedSorting, { ...item, value: role }]
        },

        updateSortingForSend(item, value) {
            this.sorting = updateSortingControl({ sortSettings: { sortBy: item.key } }, this.sorting, value)
        },

        addSortingToHeader() {
            this.selectedSorting.forEach((sort) => {
                const obj = {
                    sortSettings: {
                        sortBy: sort.key,
                    },
                    ...sort,
                }

                this.visibleHeadersTable = updateSortHeaderColumn(obj, this.visibleHeadersTable)
            })
        },

        updateFilterAfterQueryLocalStorage(savedFilter) {
            const sortFilterTableStore = useSortFilterTableStore()

            const filters = []

            Object.keys(savedFilter).forEach((key) => {
                const findItem = this.filters.find((el) => el.key === key)

                if (!findItem) {
                    return
                }

                const newItem = {
                    ...findItem,
                    value: savedFilter[key].value,
                    rule: FILTER_RULES[findItem.type].find((rule) => rule.key === savedFilter[key].rule),
                }

                filters.push(newItem)
            })

            if (!filters.length) {
                return
            }

            this.selectedFilters = filters

            if (viewPorts.mob <= window.innerWidth) {
                sortFilterTableStore.changeIndexOpen(SETTINGS_HEADER_TABLE.leads, -2)
                sortFilterTableStore.openCloseFilter(SETTINGS_HEADER_TABLE.leads)
            }
        },

        updateSortingAfterQueryLocalStorage(savedSort) {
            const sortFilterTableStore = useSortFilterTableStore()

            if (!Object.keys(savedSort).length) {
                return
            }

            Object.keys(savedSort).forEach((key) => {
                const findItem = this.sorting.find((el) => el.key === key)

                if (findItem) {
                    this.createSelectedSorting(findItem, savedSort[key])
                    this.updateSortingForSend(findItem, true)
                    sortFilterTableStore.openCloseSorting(true)
                }
            })

            this.addSortingToHeader()
        },

        goToSetFilters({ filters }) {
            this.filters = filters
            this.updateFilterAfterQueryLocalStorage(this.filtersForSend)
            this.visibleHeadersTable = addFilterInColumn(this.visibleHeadersTable, this.filters)
        },

        goToSetSorting({ sorting }) {
            this.sorting = sorting
            this.updateSortingAfterQueryLocalStorage(this.sortingForSend)
        },

        changeOnPage(onPage) {
            this.onPage = onPage

            setFilterOnPageInLocalStorageByWorkspaceId(onPage, SETTINGS_HEADER_TABLE.leads)

            this.goToLoadLeads()
        },

        checkValidPage() {
            if (this.page > this.total) {
                this.changePage(this.total)
            } else {
                this.loading = false
            }
        },

        changePage(newPage) {
            this.page = newPage

            this.goToLoadLeads()
        },

        goToAfterGlobalSearch(searchValue) {
            setTimeout(() => {
                this.getQueryRange()
                this.getLocalStorageRange()
                this.goToSetRange()
                this.onSearch(searchValue)
            }, 0)
        },

        onSearch(searchValue) {
            this.search = searchValue

            if (!this.firstLoading) {
                this.changePage(1)
            }
        },

        async goToLoadLeadsFiltersAndSorting() {
            const defaultStore = useDefaultStore()

            const { success, payload, message } = await getLeadsFiltersAndSorting()

            if (success) {
                this.goToSetFilters(payload)
                this.goToSetSorting(payload)
            } else {
                defaultStore.setErrorMessage({ message })
            }
        },

        async goToDeleteLead(slugs) {
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { success, message } = await deleteLeads({ slugs })

            if (success) {
                this.goToLoadLeads()

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return success
        },

        async goToLoadLeads() {
            this.clearSelected()

            this.loading = true

            const { success, payload, message } = await getLeads({
                page: this.page,
                search: removeFirstPlusForSearch(this.search),
                onPage: this.onPage,
                ...this.filtersForSend,
                ...this.sortingForSend,
                ...this.bodyDateForSend,
            })

            if (success) {
                const { data = [], current_page, last_page } = payload

                this.items = data
                this.page = current_page
                this.total = last_page

                this.checkValidPage()
            } else {
                const defaultStore = useDefaultStore()
                defaultStore.setErrorMessage({ message })
            }

            this.loading = false
        },

        async goToExportLeads(type) {
            const defaultStore = useDefaultStore()

            this.loadingCSV = true

            const res = await exportLeads({
                type,
                ...this.bodyDateForSend,
            })

            if (res?.data && res.data.success === false) {
                !!res.data.message && defaultStore.setErrorMessage({ message: res.data.message })
            } else if (res?.success === false) {
                !!res.message && defaultStore.setErrorMessage({ message: res.message })
            } else {
                downloadFile(res)
            }

            this.loadingCSV = false
        },

        async getQueryLocalStorage() {
            this.getLocalStorageHeadersTable()
            this.setCurrentTimezone(getCurrentTimezone(SETTINGS_HEADER_TABLE.leads) || useAuthorizationStore().getWorkspaceTimezoneId)

            const value = getClearQueryLocalStorage()

            if (value) {
                this.getQueryRange()
                this.getQueryFilter()
                this.getQuerySorting()
                this.getLocalStorageRange()
                this.getLocalStorageFilter()
                this.getLocalStorageSorting()
                this.getLocalStorageOnPage()
            }

            this.goToSetRange()
            await this.goToLoadLeads()

            this.firstLoading = false
        },

        saveFiltersSortingInLocalStorage() {
            setRangeInLocalStorageByWorkspaceId(this.rangeForSend, SETTINGS_HEADER_TABLE.leads)
            setSortingInLocalStorageByWorkspaceId(this.sortingForSend, SETTINGS_HEADER_TABLE.leads)
            setFilterInLocalStorageByWorkspaceId(this.filtersForSend, SETTINGS_HEADER_TABLE.leads)
        },

        saveQuery() {
            const query = {
                ...setQuery(this.sortingForSend, this.filtersForSend),
                ...this.rangeForSend,
            }
            this.router.replace({ query }).catch(() => {})
        },

        goToSetRange() {
            if (!Object.keys(this.rangeForSend).length) {
                this.setDefaultRange()
                return
            }

            const { range, preset } = this.rangeForSend

            const findPreset = this.presets.find((p) => p.key === preset)

            if (findPreset && preset !== 'customRange') {
                this.range = findPreset.range
                this.rangeForSend.range = findPreset.range

                this.saveFiltersSortingInLocalStorage()
                this.saveQuery()
            } else {
                this.range = range
            }
        },

        getQueryRange() {
            const { range = [], preset } = getQuery()

            if (range.length) {
                this.rangeForSend = { range, preset }
                setRangeInLocalStorageByWorkspaceId(this.rangeForSend, SETTINGS_HEADER_TABLE.leads)
            }
        },

        getQueryFilter() {
            const { filter = {} } = getQuery()

            if (Object.keys(filter).length) {
                this.filtersForSend = filter
                setFilterInLocalStorageByWorkspaceId(this.filtersForSend, SETTINGS_HEADER_TABLE.leads)
            }
        },

        getQuerySorting() {
            const { sorting = {} } = getQuery()

            if (Object.keys(sorting).length) {
                this.sortingForSend = sorting
                setSortingInLocalStorageByWorkspaceId(this.sortingForSend, SETTINGS_HEADER_TABLE.leads)
            }
        },

        getLocalStorageRange() {
            const savedRange = getRangeLocalStorageByWorkspaceId(SETTINGS_HEADER_TABLE.leads)

            if (savedRange && Object.keys(savedRange).length) {
                this.rangeForSend = savedRange
                this.saveQuery()
            }
        },

        getLocalStorageFilter() {
            const savedFilter = getFilterLocalStorageByWorkspaceId(SETTINGS_HEADER_TABLE.leads)

            if (savedFilter && Object.keys(savedFilter).length) {
                this.filtersForSend = savedFilter
                this.saveQuery()
            }
        },

        getLocalStorageSorting() {
            const savedSort = getSortingInLocalStorageByWorkspaceId(SETTINGS_HEADER_TABLE.leads)

            if (savedSort && Object.keys(savedSort).length) {
                this.sortingForSend = savedSort
                this.saveQuery()
            }
        },

        getLocalStorageOnPage() {
            const savedOnPage = getFilterOnPageInLocalStorageByWorkspaceId(SETTINGS_HEADER_TABLE.leads)

            this.onPage = savedOnPage ? Number(savedOnPage) : 25
        },

        getLocalStorageHeadersTable() {
            this.activeColumnsTable = getActiveColumnsHeader(LEADS_HEADERS, SETTINGS_HEADER_TABLE.leads)
            this.setVisibleHeaders()
        },

        setVisibleHeaders() {
            this.visibleHeadersTable = getActiveHeadersTable(LEADS_HEADERS, this.activeColumnsTable)
        },

        changeHeaders(payload) {
            this.activeColumnsTable = payload
            this.setVisibleHeaders()
            this.visibleHeadersTable = addFilterInColumn(this.visibleHeadersTable, this.filters)
        },
    },
})
