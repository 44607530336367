import {
    format,
    subDays,
    startOfWeek,
    startOfMonth,
    lastDayOfMonth,
    startOfYear,
    intervalToDuration,
    getTime,
    addMonths,
    subMonths,
    addYears,
    subYears,
    setMonth,
    addDays,
    isAfter,
    isBefore,
    getDay,
    getWeeksInMonth,
    isToday,
    formatDistanceToNowStrict,
    sub,
    add,
} from 'date-fns'
import { formatToTimeZone, convertToLocalTime } from 'date-fns-timezone'
import {
    toDate, formatInTimeZone, zonedTimeToUtc,
} from 'date-fns-tz'
import { TIME_GROUPS } from '@/constants/timeGroups'

const isoFormat = 'yyyy-MM-dd'
const notificationFormat = 'LLL dd, yyyy'
const daysOfTheWeek = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']
const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

function defaultDate() {
    return format(new Date(), isoFormat)
}

function isDateToday(date, offset) {
    return formatDateByTzOffset(offset, new Date()) === date
}

function formatDuration(seconds) {
    const duration = intervalToDuration({ start: 0, end: seconds * 1000 })
    const str = `${duration.hours}:${duration.minutes}:${duration.seconds}`
    return str.split(':').map((i) => i = `${i}`.length === 1 ? `0${i}` : i).join(':')
}

function calculateDuration(createdAt, timezoneOffset = 'UTC') {
    const timestampDate = getTime(new Date())
    const localCreatedDate = convertToLocalTime(createdAt, { timeZone: timezoneOffset === 'utc' ? 'UTC' : timezoneOffset })

    const timestampCreatedDate = getTime(new Date(localCreatedDate))
    return (timestampDate - timestampCreatedDate) / 1000
}

function formatDateCreate(date) {
    if (!date) {
        return ''
    }
    return formatInTimeZone(date, 'UTC', 'MMM d, h:mm:ss a')
}

function formatCallLogDate(date) {
    if (!date) {
        return ''
    }
    return format(toDate(date), 'MMM d, h:mm:ss a')
}

function formatFullDate(date) {
    if (!date) {
        return ''
    }
    return format(toDate(date), 'MMM d, yyyy h:mm:ss a')
}

function formatEventsTime(date) {
    if (!date) {
        return ''
    }
    return format(toDate(date), 'h:mm:ss a')
}

function formatYearMonthDay(date) {
    if (!date) {
        return ''
    }
    return format(toDate(date), 'yyyy-MM-dd')
}

function formatEventsDate(date) {
    if (!date) {
        return ''
    }
    return format(toDate(date), 'MMM d')
}

function formatDateByTzOffset(offset, date = new Date()) {
    if (offset) {
        return formatToTimeZone(date, 'YYYY-MM-DD', { timeZone: offset === 'utc' ? 'UTC' : offset })
    }

    return defaultDate()
}

function defaultDateByTzOffset(offset, date = new Date()) {
    if (offset) {
        return formatInTimeZone(date, offset === 'utc' ? 'UTC' : offset, 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx')
    }

    return defaultDate()
}

function currentYearByTzOffset(offset, date = new Date()) {
    if (offset) {
        return formatToTimeZone(date, 'YYYY', { timeZone: offset === 'utc' ? 'UTC' : offset })
    }

    return format(new Date(), 'YYYY')
}

function currentMonthByTzOffset(offset, date = new Date()) {
    if (offset) {
        return formatToTimeZone(date, 'MMMM', { timeZone: offset === 'utc' ? 'UTC' : offset })
    }

    return format(new Date(), 'MMMM')
}

function defaultFormatDate(date) {
    return format(new Date(date), isoFormat)
}

function getDateUTC(date) {
    const fullDate = new Date(date)
    return Date.UTC(
        fullDate.getFullYear(),
        fullDate.getMonth(),
        fullDate.getDate(),
        fullDate.getHours(),
        fullDate.getMinutes(),
    )
}

function nextMonths({ date, amount = 1 }) {
    return addMonths(new Date(date), amount)
}

function prevMonths({ date, amount = 1 }) {
    return subMonths(new Date(date), amount)
}

function nextYears({ date, amount = 1 }) {
    return addYears(new Date(date), amount)
}

function prevYears({ date, amount = 1 }) {
    return subYears(new Date(date), amount)
}

function setMonthToCurrentDate({ date, index, offset }) {
    return defaultDateByTzOffset(offset, setMonth(new Date(date), index))
}

function getDatesOfMonth(lowerLimit, higherLimit, currentDate) {
    const startDate = _getStartDate(currentDate)
    const weeksInMonth = getWeeksInMonth(new Date(currentDate), { weekStartsOn: 1 })
    const daysOnBoard = weeksInMonth * 7
    const createArray = new Array(daysOnBoard).fill(1)

    return createArray.map((date, index) => {
        const momentDate = addDays(startDate, index)
        // const momentDate = formatDateByTzOffset(timezoneOffset, addDays(startDate, index))
        const month = format(toDate(momentDate), 'MMMM')
        const disabled = isAfter(lowerLimit, toDate(momentDate)) || isBefore(higherLimit, toDate(momentDate))

        return {
            day: format(toDate(momentDate), 'd'),
            date: format(toDate(momentDate), isoFormat),
            month,
            year: format(toDate(momentDate), 'yyyy'),
            dayCurrentMonth: month === format(new Date(currentDate), 'MMMM'),
            disabled,
        }
    })
}

function _getStartDate(currentDate) {
    const daysWeek = new Array(7).fill(1)

    const week = daysWeek.map((el, i) => {
        const startDateMonth = startOfMonth(new Date(currentDate))
        return subDays(startDateMonth, i)
    })

    return week.find((date) => getDay(date) === 1)
}

function getPresets(timezoneOffset) {
    if (!timezoneOffset) {
        return {}
    }

    return [
        {
            key: 'today',
            name: 'Today',
            range: [
                formatDateByTzOffset(timezoneOffset),
                formatDateByTzOffset(timezoneOffset),
            ],
        }, {
            key: 'yesterday',
            name: 'Yesterday',
            range: [
                formatDateByTzOffset(timezoneOffset, subDays(new Date(), 1)),
                formatDateByTzOffset(timezoneOffset, subDays(new Date(), 1)),
            ],
        }, {
            key: 'thisWeek',
            name: 'This Week',
            range: [
                format(startOfWeek(new Date(), { weekStartsOn: 1 }), isoFormat),
                formatDateByTzOffset(timezoneOffset),
            ],
        }, {
            key: 'last7Days',
            name: 'Last 7 Days',
            range: [
                formatDateByTzOffset(timezoneOffset, subDays(new Date(), 6)),
                formatDateByTzOffset(timezoneOffset),
            ],
        }, {
            key: 'last30Days',
            name: 'Last 30 Days',
            range: [
                formatDateByTzOffset(timezoneOffset, subDays(new Date(), 29)),
                formatDateByTzOffset(timezoneOffset),
            ],
        }, {
            key: 'thisMonth',
            name: 'This Month',
            range: [
                format(startOfMonth(new Date()), isoFormat),
                formatDateByTzOffset(timezoneOffset),
            ],
        }, {
            key: 'lastMonth',
            name: 'Last Month',
            range: [
                format(startOfMonth(subMonths(new Date(), 1)), isoFormat),
                format(lastDayOfMonth(subMonths(new Date(), 1)), isoFormat),
            ],
        }, {
            key: 'thisYear',
            name: 'This Year',
            range: [
                format(startOfYear(new Date()), isoFormat),
                formatDateByTzOffset(timezoneOffset),
            ],
        }, {
            key: 'customRange',
            name: 'Custom Range',
        },
    ]
}

function setTimeGroupIdByRange(range) {
    const rangeUTC = getDateUTC(new Date(range[1])) - getDateUTC(new Date(range[0]))

    const months = rangeUTC / (31 * 24 * 3600 * 1000)

    if (months > 3) {
        return TIME_GROUPS.byMonth.id
    } if (months === 0) {
        return TIME_GROUPS.byHour.id
    }
    return TIME_GROUPS.byDay.id
}

function midnightOfToday() {
    return new Date(new Date().setHours(0, 0, 0, 0))
}

function getDateDistanceToNow(date) {
    const result = sub(new Date(date), {
        hours: new Date().getHours(),
        minutes: new Date().getMinutes(),
    })

    let delta = Math.floor((result - midnightOfToday()) / 1000)

    if (delta < 0) {
        return { days: 0, hours: 0, minutes: 0 }
    }

    const days = Math.floor(delta / 86400)
    delta -= days * 86400

    const hours = Math.floor(delta / 3600) % 24
    delta -= hours * 3600

    const minutes = Math.floor(delta / 60) % 60

    delta -= minutes * 60
    const seconds = delta % 60

    return {
        days, hours, minutes, seconds,
    }
}

function formatDateForNotification(date) {
    if (isToday(new Date(date))) {
        return `${formatDistanceToNowStrict(new Date(date))} ago`
    }

    return format(new Date(date), 'yyyy-MM-dd')
}

function getDateDistanceToEndDateGettingStart(date) {
    const dateFormat = add(new Date(date), { days: 14 })
    return isBefore(new Date(date), dateFormat)
}

function utcTimezoneFormat(hours, minutes, pattern) {
    const utcDate = zonedTimeToUtc('1970-01-01 00:00:00', 'Europe/Minsk')
    utcDate.setHours(hours)
    utcDate.setMinutes(minutes)
    return format(utcDate, pattern)
}

export {
    defaultDate,
    isDateToday,
    formatDuration,
    calculateDuration,
    formatCallLogDate,
    formatFullDate,
    formatEventsTime,
    formatEventsDate,
    formatYearMonthDay,
    formatDateByTzOffset,
    defaultDateByTzOffset,
    currentYearByTzOffset,
    currentMonthByTzOffset,
    defaultFormatDate,
    getDateUTC,
    nextMonths,
    prevMonths,
    nextYears,
    prevYears,
    setMonthToCurrentDate,
    getDatesOfMonth,
    getPresets,
    setTimeGroupIdByRange,
    formatDateForNotification,
    getDateDistanceToNow,
    getDateDistanceToEndDateGettingStart,
    utcTimezoneFormat,
    formatDateCreate,
    isoFormat,
    daysOfTheWeek,
    months,
}
